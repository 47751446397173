// Personnel
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { toDate } from "date-fns-tz";

// Raw response data as returned from LBAPI
export interface PersonnelAttributes {
  filtered: boolean;
  emp_id: number;
  user_id: null | number;
  ptype_id: number;
  display_name: string;
  first_name: string;
  last_name: string;
  compact_name: string;
  phone_business: string | null;
  phone_home: string | null;
  phone_cell: string | null;
  pager: string | null;
  email: string | null;
  unique_id: string | null;
  custom_1: string | null;
  custom_2: string | null;
  custom_3: string | null;
  custom_4: string | null;
  weekly_hours: null | number;
  seniority_date: null | string;
  title: string | null;
  first_active_date: string | null;
  last_active_date: string | null;
  expired: boolean;
  scheduled: boolean;
  departments: number[];
  accessible_views: unknown[];
  member_of_filters: unknown[];
  roles: number[];
  sm_account: string | null;
  sm_provider: string | null;
  sm_device: string | null;
  sm_domain: string | null;
  email_to_pager: string | null;
  pager_provider: string | null;
  order: number;
  color: string | null;
  colorText: string | null;
  notification_email: string | null;
}

// Cleaned up data model
export interface Personnel {
  empId: number | "";
  userId: number | "";
  uniqueId: string;
  pTypeId: number | "";
  compactOrDisplayName: string;
  firstName: string;
  lastName: string;
  title: string;
  firstActiveDate: Date | null;
  lastActiveDate: Date | null;
  seniorityDate: Date | null;
  expired: boolean;
  scheduled: boolean;
  departments: number[];
  weeklyHours: number | null;
  color: string | null;
  colorText: string | null;
  order: number | null;
  businessPhoneNumber: string;
  cellPhoneNumber: string;
  homePhoneNumber: string;
  email: string;
  pager: string;
  roles: number[];
  smAccount: string;
  smProvider: string;
  smDevice: string;
  smDomain: string;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  notificationEmail: string;
}

export const cleanPersonnelAttributes = (settings: SettingsContext, attrs: Partial<PersonnelAttributes>): Personnel => {
  return {
    empId: attrs.emp_id ?? "",
    userId: attrs.user_id ?? "",
    uniqueId: attrs.unique_id ?? "",
    pTypeId: attrs.ptype_id ?? "",
    compactOrDisplayName: settings.isCompact ? attrs.compact_name ?? "" : attrs.display_name ?? "",
    firstName: attrs.first_name ?? "",
    lastName: attrs.last_name ?? "",
    title: attrs.title ?? "",
    firstActiveDate: attrs.first_active_date ? toDate(attrs.first_active_date) : null,
    lastActiveDate: attrs.last_active_date ? toDate(attrs.last_active_date) : null,
    seniorityDate: attrs.seniority_date ? toDate(attrs.seniority_date) : null,
    expired: attrs.expired ?? false,
    scheduled: attrs.scheduled ?? false,
    departments: attrs.departments ?? [],
    weeklyHours: attrs.weekly_hours ?? null,
    color: attrs.color ?? "",
    colorText: attrs.colorText ?? "",
    order: attrs.order ?? null,
    businessPhoneNumber: attrs.phone_business ?? "",
    cellPhoneNumber: attrs.phone_cell ?? "",
    homePhoneNumber: attrs.phone_home ?? "",
    email: attrs.email ?? "",
    pager: attrs.pager ?? "",
    roles: attrs.roles ?? [],
    smAccount: attrs.sm_account ?? "",
    smProvider: attrs.sm_provider ?? "",
    smDevice: attrs.sm_device ?? "",
    smDomain: attrs.sm_domain ?? "",
    custom1: attrs.custom_1 ?? "",
    custom2: attrs.custom_2 ?? "",
    custom3: attrs.custom_3 ?? "",
    custom4: attrs.custom_4 ?? "",
    notificationEmail: attrs.notification_email ?? "",
  };
};

export type PersonnelById = Record<string, Personnel>;
