/* eslint @typescript-eslint/no-var-requires: "off" */
"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
var Promise = require("bluebird");
// models
var Personnel = require("_lib/data/models/NakedPersonnel.js");
// componentsSlotDetail
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
var EMPTY_PERSONNEL_PLACEHOLDER_ID = require("../../../../../../viewer/data/constants").EMPTY_PERSONNEL_PLACEHOLDER_ID;
const { LDFlagEnums } = require("@/_lib/constants/LDFlagEnums");
const SlotEditDialog = require("../slot_edit/SlotEditDialog");

var SlotDetailsDialogMain = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {
      activeTab: "general",
    };
  },
  componentWillUnmount: function () {
    window.LbsAppData.AppContext.off(null, null, this);
  },

  /* publics */
  close: function (e) {
    this.props.close();
  },

  /* privates */
  _openEditDialog: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "slot-edit",
      data: this.props.dialogObject.data,
    });
  },
  _getPendingIconAndText: function () {
    var icon;
    var text;

    // if the employee pending is '4' then it's DOTGUY... and we're just removing ourselves
    if (this.props.dialogObject.data.attributes.pending_emp_id == EMPTY_PERSONNEL_PLACEHOLDER_ID) {
      icon = <i className="fa fa-fw fa-sign-out" />;
      text = "Pending Remove";
    } else if (this.props.dialogObject.data.attributes.emp_id == EMPTY_PERSONNEL_PLACEHOLDER_ID) {
      icon = <i className="fa fa-fw fa-sign-in" />;
      text = "Pending Fill";
    } else if (
      this.props.dialogObject.data.attributes.emp_id == this.props.dialogObject.data.attributes.pending_emp_id
    ) {
      icon = <i className="fa fa-fw fa-question" />;
      text = "Pending Details";
    } else {
      // replacing someone with someone (real) else
      if (this.props.dialogObject.data.get("pending_info").preswaps.length > 0) {
        icon = <i className="fa fa-fw fa-rss" />;
        text = "Pending Swaportunity";
      } else {
        // normal replace
        icon = <i className="fa fa-fw fa-exchange" />;
        text = "Pending Replace";
      }
    }

    return { icon: icon, text: text };
  },
  _expandNoteDialog: function (attr) {
    window.LbsAppData.AppContext.openDialog({
      type: "note-full",
      note: this.props.dialogObject.data.get(attr),
      readonly: true,
    });
  },
  _expandAssignmentInfo: function (args) {
    window.LbsAppData.AppContext.openDialog({
      type: "assignment-info",
      note: args[1] ?? "",
      readonly: true,
    });
  },
  _expandHistoryDialog: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "slot-history",
      slot: this.props.dialogObject.data,
    });
  },
  _setCurrentTab: function (id) {
    this.setState({ activeTab: id });
  },

  _getPendingDiff: function (slot) {
    var pendingInfo = slot.get("pending_info");
    var changingDict = {
      emp_id: false,
      loa_reason_name: false,
      location_names: false,
      time: false,
    };

    var pendingType;
    if (pendingInfo["emp_id"] === 4) {
      pendingType = "remove";
      changingDict.emp_id = true;
    } else if (slot.get("emp_id") === 4) {
      pendingType = "fill";
      changingDict.emp_id = true;
    } else if (pendingInfo["emp_id"] !== slot.get("emp_id")) {
      pendingType = "replace";
      changingDict.emp_id = true;
    } else if (pendingInfo["preswaps"].length) {
      pendingType = "swaportunity";
    } else if (pendingInfo["emp_id"] === slot.get("emp_id")) {
      pendingType = "details";
    }

    // possible detail changes
    if (pendingInfo["loa_reason_name"] !== slot.get("loa_reason_name")) {
      changingDict.loa_reason_name = true;
    }
    if (
      _.difference(pendingInfo["location_names"], slot.get("location_names")).length > 0 ||
      _.difference(slot.get("location_names"), pendingInfo["location_names"]).length > 0
    ) {
      changingDict.location_names = true;
    }

    if (pendingInfo["start_time"] !== slot.get("start_time") || pendingInfo["stop_time"] !== slot.get("stop_time")) {
      changingDict.time = true;
    }

    return {
      type: pendingType,
      changing: changingDict,
    };
  },

  _getSlotTime: function (slot, pendingDiff) {
    var date = moment(slot.attributes.slot_date).format("ddd, LL");
    // an undefined/null start_time means its a default time...the way requests work..thats not so easy to figure out what the default time is
    // as time is tied to structures, and requests don't have a structure until they are scheduled.
    var timeElement = {};
    if (slot.attributes.start_time) {
      var startDate = moment(slot.attributes.start_time, "YYYY-MM-DDTHH:mm:ss");
      var stopDate = moment(slot.attributes.stop_time, "YYYY-MM-DDTHH:mm:ss");
      var timeString =
        startDate.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT")) +
        " - " +
        stopDate.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"));

      timeElement = (
        <div>
          <span className="semi-large" data-cy="slotTimeString">
            {timeString}
          </span>
          <br />
        </div>
      );
    }

    return (
      <div className="time-container">
        <span className="uppercase semi-large">{date}</span>
        <br />
        {timeElement}
      </div>
    );
  },

  _getCustomDisplay: function (slot) {
    var display_name = slot.attributes.display_name_override;
    if (display_name) {
      return (
        <div className="customdisplay-container">
          <span className="uppercase semi-large">{"[" + display_name + "]"}</span>
        </div>
      );
    }
  },

  _getSlotAssignment: function (slot, pendingDiff) {
    var assignmentName = slot.attributes.assign_display_name;
    var templateText = slot.attributes.template_desc;
    var dateStr = moment(slot.attributes.slot_date).format("ddd, MMMM Do, YYYY");

    var styles;
    if (window.LbsAppData.Assignments) {
      var assignment = window.LbsAppData.Assignments.get(slot.attributes.condensed_structure_id);
      styles = assignment
        ? {
            backgroundColor: assignment.get("color"),
            color: assignment.get("colorText"),
          }
        : {};
    } else {
      styles = {};
    }

    return (
      <div className="assignment-container">
        <div className="assignment-name" style={{ backgroundColor: styles.backgroundColor }}>
          <span className="super-large underline" data-cy="slotUsersName" style={{ color: styles.color }}>
            {assignmentName}
          </span>
        </div>
        <span className="template-name">{templateText}</span>
      </div>
    );
  },

  _getSlotPersonnel: function (slot, pendingDiff) {
    var ret = [];
    var personnel = pendingDiff && pendingDiff.changing.emp_id ? this.props.personnelPending : this.props.personnel;

    const messagingHelper = window.LbsAppData.Helpers.Messaging;
    const multiSmProvider = window.LbsAppData.launchDarklyClient.evaluateLDFlag(
      LDFlagEnums.Lv8512smProviderEpic,
      false
    );
    const sm_c = multiSmProvider ? personnel?.attributes.sm_provider : window.LbsAppData.User.attributes.sm_client;
    const sm_d = personnel?.attributes.sm_domain;
    const sm_a = personnel?.attributes.sm_account;
    const secureMessagingChatLink = messagingHelper.getSecureMessagingChatLink(sm_c, sm_d, sm_a);

    // pending slot decorators
    var pendingIcon;
    if (pendingDiff) {
      switch (pendingDiff.type) {
        case "fill":
          pendingIcon = <span className="fa fa-fw fa-plus" />;
          break;
        case "remove":
          pendingIcon = <span className="fa fa-fw fa-minus" />;
          personnel = this.props.personnel; // swap personnel target
          break;
        case "replace":
          ret.push(this._getSlotPersonnel(slot));

          ret.push(
            <div className="pending-replace-icon-container pending-update current-update" key={"icon"}>
              <span className="fa fa-fw fa-arrow-right" />
            </div>
          );
          break;
        default:
          break;
      }
    }

    const getSecureMessagingChatProperties = (sm_c) => {
      switch (sm_c) {
        case "voalte":
          return {
            text: "Voalte",
            icon: require("_lib/ui/images/voalte_icon.svg"),
          };
        case "cortext":
          return {
            text: "Cortext",
            icon: require("_lib/ui/images/cortext_icon.svg"),
          };
        case "vocera":
          return {
            text: "Vocera",
            icon: require("_lib/ui/images/vocera_icon.svg"),
          };
        case "perfectserve":
          return {
            text: "PerfectServe",
            icon: require("_lib/ui/images/perfectserve_icon.svg"),
          };
        case "telmediq":
          return {
            text: "TelmedIQ",
            icon: require("_lib/ui/images/telmediq_icon.svg"),
          };
        case "epic_sc":
          return {
            text: "Secure Chat",
            icon: require("_lib/ui/images/epic_icon.svg"),
          };
        case "vina":
          return {
            text: "Vina Profile",
            icon: require("_lib/ui/images/vina_icon.svg"),
          };
        default:
          return {
            text: "",
            icon: null,
          };
      }
    };

    var contactDetailDivs = [];
    var contactMobileDivs = [];
    if (personnel && personnel.get("pager")) {
      var emailToPagerLink = undefined;
      if (personnel.get("email_to_pager")) {
        emailToPagerLink = (
          <a href={"mailto:" + personnel.get("email_to_pager")} target="_blank" rel="noreferrer">
            <span className="underline dark">Email Pager</span>
          </a>
        );
        contactMobileDivs.push(
          <div className="contact-details-item" key={"pager_email"}>
            <a href={"mailto:" + personnel.get("email_to_pager")}>
              <i className="fa fa-fw fa-envelope-o" />
              <span className="contact-details-item-title">Pager</span>
            </a>
          </div>
        );
      }
      contactDetailDivs.push(
        <div className="contact-details-item" key={"pager"}>
          <span className="contact-details-item-title">Pager</span> &mdash;{" "}
          <a href={"tel:" + personnel.get("pager")}>
            <span className="underline dark">{personnel.get("pager")}</span>
          </a>{" "}
          {emailToPagerLink}
        </div>
      );
      // mobile
      contactMobileDivs.push(
        <div className="contact-details-item" key={"pager_call"}>
          <a href={"tel:" + personnel.get("pager")}>
            <i className="fa fa-fw fa-phone" />
            <span className="contact-details-item-title">Pager</span>
          </a>
        </div>
      );
    }
    if (personnel && personnel.get("phone_cell")) {
      contactDetailDivs.push(
        <div className="contact-details-item" key={"phone_cell"}>
          <span className="contact-details-item-title">Cell</span> &mdash;{" "}
          <a href={"tel:" + personnel.get("phone_cell")} data-cy="slotDetailCellContact">
            <span className="underline dark">{personnel.get("phone_cell")}</span>
          </a>
        </div>
      );
      // mobile
      contactMobileDivs.push(
        <div className="contact-details-item" key={"phone_cell_call"}>
          <a href={"tel:" + personnel.get("phone_cell")} data-cy="slotDetailCellContact">
            <i className="fa fa-fw fa-phone" />
            <span className="contact-details-item-title">Cell</span>
          </a>
        </div>
      );
    }
    if (personnel && personnel.get("phone_business")) {
      contactDetailDivs.push(
        <div className="contact-details-item" key={"phone_business"}>
          <span className="contact-details-item-title">Biz</span> &mdash;{" "}
          <a href={"tel:" + personnel.get("phone_business")}>
            <span className="underline dark">{personnel.get("phone_business")}</span>
          </a>
        </div>
      );
      // mobile
      contactMobileDivs.push(
        <div className="contact-details-item" key={"phone_biz_call"}>
          <a href={"tel:" + personnel.get("phone_business")}>
            <i className="fa fa-fw fa-phone" />
            <span className="contact-details-item-title">Biz</span>
          </a>
        </div>
      );
    }
    if (personnel && personnel.get("phone_home")) {
      contactDetailDivs.push(
        <div className="contact-details-item" key={"phone_home"}>
          <span className="contact-details-item-title">Home</span> &mdash;{" "}
          <a href={"tel:" + personnel.get("phone_home")}>
            <span className="underline dark">{personnel.get("phone_home")}</span>
          </a>
        </div>
      );
      // mobile
      contactMobileDivs.push(
        <div className="contact-details-item" key={"phone_home_call"}>
          <a href={"tel:" + personnel.get("phone_home")}>
            <i className="fa fa-fw fa-phone" />
            <span className="contact-details-item-title">Home</span>
          </a>
        </div>
      );
    }
    if (personnel && personnel.get("email")) {
      contactDetailDivs.push(
        <div className="contact-details-item" key={"email"}>
          <span className="contact-details-item-title">Email</span> &mdash;{" "}
          <a href={"mailto:" + personnel.get("email")} className="contact-item call" key={"email"}>
            <span className="underline dark">{personnel.get("email")}</span>
          </a>
        </div>
      );
      // mobile
      contactMobileDivs.push(
        <div className="contact-details-item" key={"email"}>
          <a href={"mailto:" + personnel.get("email")} className="contact-item call" key={"email"}>
            <i className="fa fa-fw fa-envelope-o" />
            <span className="contact-details-item-title">Email</span>
          </a>
        </div>
      );
    }
    if (secureMessagingChatLink) {
      const { text, icon } = getSecureMessagingChatProperties(sm_c);

      contactDetailDivs.push(
        <div className="contact-details-item" key={"sm"}>
          <a href={secureMessagingChatLink} target="_blank" rel="noreferrer" key={"sm"}>
            <span className="contact-details-item-title underline dark bold">{text}</span>
          </a>
        </div>
      );
      // mobile
      contactMobileDivs.push(
        <div className="contact-details-item" key={"sm"}>
          <a href={secureMessagingChatLink} target="_blank" rel="noreferrer" key={"sm"}>
            <img src={icon} alt="Icon" />
            <span className="contact-details-item-title">{text}</span>
          </a>
        </div>
      );
    }

    var contactDetails;
    if (contactDetailDivs.length) {
      contactDetails = (
        <div className="contact-details">
          <div className="contact-details-content">{contactDetailDivs}</div>
          <div className="contact-details-content-mobile">{contactMobileDivs}</div>
        </div>
      );
    }

    var styles = this.props.personnel
      ? {
          backgroundColor: this.props.personnel.get("color"),
          color: this.props.personnel.get("colorText"),
        }
      : {};

    var sectionCustomDisplay = this._getCustomDisplay(slot);
    ret.push(
      <div
        className={
          pendingDiff && pendingDiff.changing.emp_id
            ? "personnel-container pending-update"
            : "personnel-container current-update"
        }
        key={"personnel" + (pendingDiff ? "_pending" : "")}
      >
        <div className="personnel-name" data-cy="slotDetailName" style={{ backgroundColor: styles.backgroundColor }}>
          <span className="super-large underline" style={{ color: styles.color }}>
            {pendingIcon}
            {personnel ? personnel.get("display_name") : ""}
          </span>
          {sectionCustomDisplay}
        </div>
        {contactDetails}
      </div>
    );

    return ret;
  },

  _getSlotNote: function (slot, pendingDiff) {
    var ret;
    if (slot.attributes.note) {
      //<div className='slot-note-title'>Note</div>
      ret = (
        <div className="slot-note-container">
          <div className="slot-note-content">
            <span className="message">
              <span className="message-text" data-cy="SDMessageText">
                {slot.attributes.note}
              </span>
              <span className="expand-text" onClick={this._expandNoteDialog.bind(this, "note")}>
                more
              </span>
            </span>
          </div>
        </div>
      );
    }

    return ret;
  },

  _getSlotDetails: function (slot, pendingDiff) {
    var ret;
    var additionalDetailDivs = [];

    //call order
    if (slot.attributes.call_order && this.props?.flags[LDFlagEnums.Lv6192ShowAssignmentCallOrder]) {
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"call-order"}>
          <div className="additional-details-item-department-text-container">
            <span className="additional-details-item-title">Call Order</span>&nbsp;&mdash;&nbsp;
            <span className="dark department-name-overflow">{slot.attributes.call_order}</span>
          </div>
        </div>
      );
    }

    // department
    if (slot.attributes.department_id) {
      let departmentName = window.LbsAppData.Departments?.get(slot.attributes.department_id)?.get("name");

      additionalDetailDivs.push(
        <div className="additional-details-item" key={"department_id"}>
          <div className="additional-details-item-department-text-container">
            <span className="additional-details-item-title">Department</span>&nbsp;&mdash;&nbsp;
            <span className="dark department-name-overflow">{departmentName}</span>
          </div>
        </div>
      );
    }
    // template
    if (slot.attributes.template) {
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"template"}>
          <div className="additional-details-item-text-container">
            <span className="additional-details-item-title">Template</span> &mdash;{" "}
            <span className="dark">{slot.attributes.template_desc}</span>
          </div>
        </div>
      );
    }
    // locations
    // --existing
    if (slot.attributes.location_names.length) {
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"location"}>
          <div className="additional-details-item-text-container">
            <span className="additional-details-item-title">Location</span> &mdash;{" "}
            <span className="dark">{slot.attributes.location_names.join(", ")}</span>
          </div>
        </div>
      );
    }
    // --pending
    if (pendingDiff && pendingDiff.changing.location_names) {
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"location-pending"}>
          <div className="additional-details-item-text-container pending-update">
            <span className="additional-details-item-title">Location (Pending)</span> &mdash;{" "}
            <span className="dark">{slot.get("pending_info").location_names.join(", ")}</span>
          </div>
        </div>
      );
    }
    // loa reasons
    // -- existing
    if (slot.attributes.loa_reason_name) {
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"loa-existing"}>
          <div className="additional-details-item-text-container">
            <span className="additional-details-item-title">Reason</span> &mdash;{" "}
            <span className="dark">{slot.attributes.loa_reason_name}</span>
          </div>
        </div>
      );
    }
    // Assignment Information
    if (slot?.assignmentObj?.attributes?.description) {
      const assignId = slot?.attributes?.assign_id;
      const note = slot?.assignmentObj?.attributes?.assignment_to_note_map[assignId] ?? "";

      additionalDetailDivs.push(
        <span className="assignment-info" key={slot.emp_id + slot.department_id + slot.id}>
          <span className="assignment-text" data-cy="SDAssignmentInfo">
            <span>Assignment Information</span> &mdash; <span>{note}</span>
          </span>
          <span className="expand-text" onClick={this._expandAssignmentInfo.bind(this, ["assignment-info", note])}>
            more
          </span>
        </span>
      );
    }

    if (pendingDiff && pendingDiff.changing.loa_reason_name) {
      // -- pending
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"loa-pending"}>
          <div className="additional-details-item-text-container pending-update">
            <span className="additional-details-item-title">Reason (Pending)</span> &mdash;{" "}
            <span className="dark">{slot.get("pending_info").loa_reason_name}</span>
          </div>
        </div>
      );
    }
    // originally scheduled
    if (slot.attributes.original_emp_id) {
      var origEmp = window.LbsAppData.Personnel
        ? window.LbsAppData.Personnel.get(slot.attributes.original_emp_id)
        : undefined;
      var origEmpName = origEmp ? origEmp.get("display_name") : "N/A";
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"original_emp_id"}>
          <div className="additional-details-item-text-container current-update">
            <span className="additional-details-item-title">Originally Scheduled</span> &mdash;{" "}
            <span className="dark">{origEmpName}</span>
          </div>
        </div>
      );
    }
    // pending time
    if (pendingDiff && pendingDiff.changing.time) {
      var startDate = moment(slot.get("pending_info").start_time, "YYYY-MM-DDTHH:mm:ss");
      var stopDate = moment(slot.get("pending_info").stop_time, "YYYY-MM-DDTHH:mm:ss");
      var timeString =
        startDate.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT")) +
        " - " +
        stopDate.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"));
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"time-pending"}>
          <div className="additional-details-item-text-container pending-update">
            <span className="additional-details-item-title">Time (Pending)</span> &mdash;{" "}
            <span className="dark">{timeString}</span>
          </div>
        </div>
      );
    }

    if (additionalDetailDivs.length) {
      ret = (
        <div className="additional-details">
          <div className="additional-details-title">Details</div>
          <div className="additional-details-content">{additionalDetailDivs}</div>
        </div>
      );
    }

    return ret;
  },

  _getSlotHistory: function (slot) {
    var ret;

    var additionalDetailDivs = [];
    if (slot.attributes.modified_date || (slot.attributes.is_pending && slot.attributes.pending_info.timestamp)) {
      var modDate = slot.attributes.is_pending ? slot.attributes.pending_info.timestamp : slot.attributes.modified_date;
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"modified_date"}>
          <div className="additional-details-item-text-container">
            <span className="additional-details-item-title" data-cy="lastModified">
              Last modified
            </span>{" "}
            &mdash;{" "}
            <span className="dark">
              {moment(modDate).format(window.LbsAppData.Helpers.Time.preferredTimeFormat("llll"))}
            </span>
          </div>
        </div>
      );
    }
    if (slot.attributes.is_pending && slot.attributes.pending_info.modified_by_display_name) {
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"modified_by_display_name"}>
          <div className="additional-details-item-text-container">
            <span className="additional-details-item-title">Last modified by</span> &mdash;{" "}
            <span className="dark">{slot.attributes.pending_info.modified_by_display_name}</span>
          </div>
        </div>
      );
    } else if (slot.attributes.modified_by_display_name) {
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"modified_by_display_name"}>
          <div className="additional-details-item-text-container">
            <span className="additional-details-item-title">Last modified by</span> &mdash;{" "}
            <span className="dark">{slot.attributes.modified_by_display_name}</span>
          </div>
        </div>
      );
    }

    var modificationMessage;
    if (slot.attributes.decision_note) {
      modificationMessage = (
        <span className="message">
          <sup>
            <i className="fa fa-quote-left" />
          </sup>
          <span className="message-text" data-cy="SDMessageText">
            {slot.attributes.decision_note}
          </span>
          <span className="expand-text" onClick={this._expandNoteDialog.bind(this, "decision_note")}>
            more
          </span>
          <sup>
            <i className="fa fa-quote-right" />
          </sup>
        </span>
      );
    }

    var title;
    if (slot.attributes.slot_history.length) {
      title = (
        <div>
          <div className="additional-details-title">History</div>
          <span className="history-link" onClick={this._expandHistoryDialog}>
            view {slot.attributes.slot_history.length} entries
          </span>
        </div>
      );
    } else {
      title = <div className="additional-details-title">History</div>;
    }

    if (additionalDetailDivs.length) {
      ret = (
        <div className="additional-details current-update">
          {title}
          <div className="additional-details-content">{additionalDetailDivs}</div>
          {modificationMessage}
        </div>
      );
    }

    return ret;
  },

  _getSlotRequestNote: function (slot) {
    var ret;
    if (slot.attributes.is_granted_request) {
      //<div className='slot-note-title'>Note</div>
      var message;
      if (slot.attributes.request_note) {
        message = (
          <span className="message">
            <span className="message-text" data-cy="SDMessageText">
              {slot.attributes.request_note}
            </span>
            <span className="expand-text" onClick={this._expandNoteDialog.bind(this, "request_note")}>
              more
            </span>
          </span>
        );
      } else {
        message = <span className="empty-details">None.</span>;
      }

      ret = (
        <div className="slot-request-note-container current-update">
          <div className="slot-request-note-title">Request Info</div>
          <div className="slot-request-note-content">{message}</div>
        </div>
      );
    }

    return ret;
  },

  _getSlotSwappers: function (slot, swappers) {
    var ret;

    var swapperDivs = [];
    for (var i = 0; i < swappers.length; i++) {
      var item = swappers[i];
      swapperDivs.push(
        <div className="swapper-details-item" key={i}>
          <span className="swapper-details-item-name">{item.display_name}</span>
        </div>
      );
    }
    ret = (
      <div className="swapper-details">
        <div className="swapper-details-content">{swapperDivs}</div>
      </div>
    );

    return ret;
  },

  _getSlotPendingInfo: function (slot) {
    var ret;

    var pendingDisplay, pendingPersonnel, pendingNote, pendingDetails;
    var pendingDiff = this._getPendingDiff(slot);
    switch (pendingDiff.type) {
      case "replace":
      case "fill":
        pendingPersonnel = this._getSlotPersonnel(slot, pendingDiff);
      case "remove":
      case "details":
        pendingDetails = this._getSlotDetails(slot, pendingDiff);

        var displayBlocks = [];
        if (pendingPersonnel) {
          displayBlocks.push(
            <div className="changing-item" key={"personnel"}>
              <div className="changing-item-content">{pendingPersonnel}</div>
            </div>
          );
        }
        if (pendingNote) {
          displayBlocks.push(
            <div className="changing-item" key={"note"}>
              <div className="changing-item-title">Update note to:</div>
              <div className="changing-item-content">{pendingNote}</div>
            </div>
          );
        }
        if (pendingDetails) {
          displayBlocks.push(
            <div className="changing-item" key={"details"}>
              <div className="changing-item-title">Details</div>
              <div className="changing-item-content">{pendingDetails}</div>
            </div>
          );
        }
        pendingDisplay = <div className="changing-container">{displayBlocks}</div>;
        break;
      case "swaportunity":
        var acceptedSwappers = _.filter(slot.get("pending_info").preswaps, function (ps) {
          return ps.response == 2;
        });
        var declinedSwappers = _.filter(slot.get("pending_info").preswaps, function (ps) {
          return ps.response == 1;
        });
        var pendingSwappers = _.filter(slot.get("pending_info").preswaps, function (ps) {
          return ps.response == 0;
        });

        var displayBlocks = [];
        displayBlocks.push(
          <div className="changing-item swop accepted" key={"accepted"}>
            <div className="changing-item-title">Accepted &mdash; {acceptedSwappers.length}</div>
            <div className="changing-item-content">{this._getSlotSwappers(slot, acceptedSwappers)}</div>
          </div>
        );
        displayBlocks.push(
          <div className="changing-item swop decisionless" key={"pending"}>
            <div className="changing-item-title">Pending &mdash; {pendingSwappers.length}</div>
            <div className="changing-item-content">{this._getSlotSwappers(slot, pendingSwappers)}</div>
          </div>
        );
        displayBlocks.push(
          <div className="changing-item swop denied" key={"declined"}>
            <div className="changing-item-title">Declined &mdash; {declinedSwappers.length}</div>
            <div className="changing-item-content">{this._getSlotSwappers(slot, declinedSwappers)}</div>
          </div>
        );
        pendingDisplay = <div className="swaportunity-container">{displayBlocks}</div>;
        break;
      default:
        break;
    }

    return (
      <div className="pending pending-update">
        <div className="pending-title">Pending {pendingDiff.type}</div>

        <div className="pending-body">
          <div className="submission-details">
            <div className="submission-details-content">
              <div className="submission-details-item" key={"timestamp"}>
                <span className="submission-details-item-title">Submitted</span> &mdash;{" "}
                <span className="dark">
                  {moment(slot.get("pending_info").timestamp).format(
                    window.LbsAppData.Helpers.Time.preferredTimeFormat("llll")
                  )}
                </span>
              </div>
            </div>
          </div>

          {pendingDisplay}
        </div>
      </div>
    );
  },

  /* render */
  render: function () {
    /* jshint ignore:start */
    var slot = this.props.dialogObject.data;
    var pendingInfo;

    var sectionTime,
      sectionAssignment,
      sectionPersonnel,
      sectionNote,
      sectionDetails,
      sectionPending,
      sectionRequestDetails,
      sectionModified,
      content;
    if (slot && !this.props.loading) {
      // pending data
      pendingInfo = slot.get("is_pending") ? this._getPendingDiff(slot) : undefined;
      var sectionHeader;
      if (pendingInfo) {
        sectionHeader = (
          <div className="header">
            <div className="mobile-section-nav">
              <div
                className={
                  this.state.activeTab == "general"
                    ? "mobile-section-nav-tab group-1 active"
                    : "mobile-section-nav-tab group-1"
                }
                onClick={this._setCurrentTab.bind(this, "general")}
              >
                Pending {pendingInfo.type}
              </div>
              <div
                className={
                  this.state.activeTab == "general"
                    ? "mobile-section-nav-tab group-2 active"
                    : "mobile-section-nav-tab group-2"
                }
                onClick={this._setCurrentTab.bind(this, "general")}
              >
                Scheduled
              </div>
              <div
                className={
                  this.state.activeTab == "pending"
                    ? "mobile-section-nav-tab group-2 active"
                    : "mobile-section-nav-tab group-2"
                }
                onClick={this._setCurrentTab.bind(this, "pending")}
              >
                Pending
              </div>
            </div>
          </div>
        );
      } else {
        sectionHeader = (
          <div className="header">
            <div className="mobile-section-nav">
              <div
                className={
                  this.state.activeTab == "general" ? "mobile-section-nav-tab active" : "mobile-section-nav-tab"
                }
                onClick={this._setCurrentTab.bind(this, "general")}
              >
                Scheduled
              </div>
            </div>
          </div>
        );
      }

      // core data
      const sectionTime = this._getSlotTime(slot, pendingInfo);
      const sectionAssignment = this._getSlotAssignment(slot, pendingInfo);
      const sectionPersonnel = this._getSlotPersonnel(slot, pendingInfo);
      const sectionNote = this._getSlotNote(slot, pendingInfo);
      const sectionDetails = this._getSlotDetails(slot, pendingInfo);
      const sectionHistory = this._getSlotHistory(slot);
      const sectionRequestDetails = this._getSlotRequestNote(slot);

      //{sectionPending}
      if (pendingInfo && pendingInfo.type === "swaportunity") {
        // swop panel
        sectionPending = this._getSlotPendingInfo(slot);
      }

      let slotEditContent;
      if (this.props?.flags[LDFlagEnums.Lv7029MoveEditButtonsIntoSlotDetailsWindow]) {
        slotEditContent = (
          <div>
            <SlotEditDialog isDialog={false} dialogObject={this.props.dialogObject} />
          </div>
        );
      } else {
        slotEditContent = (
          <div
            className="edit-button"
            data-cy="editBtn"
            onClick={this.touchProxy.bind(this, this._openEditDialog, [])}
            onTouchEnd={this.touchProxy.bind(this, this._openEditDialog, [])}
          >
            Edit
          </div>
        );
      }

      content = (
        <div className="content">
          {sectionHeader}
          <div className="content-body">
            <div className="details">
              {sectionAssignment}
              {sectionTime}
              {sectionPersonnel}
              {sectionNote}

              <div className="details-ext">
                {sectionDetails}
                {sectionHistory}
                {sectionRequestDetails}
              </div>
            </div>

            {sectionPending}

            <div className="footer">{slotEditContent}</div>
          </div>
        </div>
      );
    } else {
      content = (
        <div className="content">
          <div className="header">
            <span className="tag">{slot && slot.get("is_pending") ? "Pending" : "Scheduled"}</span>
          </div>

          <div className="content-body">
            <div className="loading-container">
              <span className="fa fa-fw fa-spin fa-spinner" />
            </div>
          </div>
        </div>
      );
    }

    var classes = React.addons.classSet({
      Dialog: true,
      SlotDetails: true,

      scheduled: !slot || !slot.get("is_pending"),
      pending: slot && slot.get("is_pending"),
      expanded: pendingInfo && "replace!swaportunity".indexOf(pendingInfo.type) > -1,
      swop: pendingInfo && "swaportunity".indexOf(pendingInfo.type) > -1,

      "general-active": this.state.activeTab == "general",
      "pending-active": this.state.activeTab == "pending",
    });

    //prevent dragging of mouse on modal from moving the background
    const consumeDrag = (event) => {
      event.stopPropagation();
    };

    return (
      <div className={classes} data-cy="SlotDetails" onMouseDown={consumeDrag}>
        <div className="position-reset">
          <div
            className="close"
            data-cy="slotCloseBtn"
            onClick={this.touchProxy.bind(this, this.close, [])}
            onTouchEnd={this.touchProxy.bind(this, this.close, [])}
          >
            <i className="fa fa-close" />
          </div>

          {content}
        </div>
      </div>
    );
  },
});

module.exports = SlotDetailsDialogMain;
